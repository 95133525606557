import "./styles/App.css";

import Home from "./modules/home";
import NewsDetail from "./modules/newsDetail";
import About from "./modules/about";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import themeAntd from "./styles/themeAntd";
import LoginERP from "./modules/erp/login";
import ERP from "./modules/erp";
import { HelmetProvider } from "react-helmet-async";
import NotFound from "./modules/notFound";

const helmetContext = {};

const App = ({ authorized, firebase, user }) => (
  <HelmetProvider context={helmetContext}>
    <ConfigProvider theme={{ token: themeAntd }}>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home authorized={authorized} firebase={firebase} user={user} />
            }
          />
          <Route
            exact
            path="/news/:id"
            element={
              <NewsDetail
                authorized={authorized}
                firebase={firebase}
                user={user}
              />
            }
          />
          <Route
            exact
            path="/about-us"
            element={
              <About authorized={authorized} firebase={firebase} user={user} />
            }
          />
          {/*Admin page */}
          <Route
            exact
            path="/login-kk-weekly-news-back-office"
            element={
              <LoginERP
                authorized={authorized}
                firebase={firebase}
                user={user}
              />
            }
          />
          <Route
            exact
            path="/kk-weekly-news-back-office"
            element={
              <ERP authorized={authorized} firebase={firebase} user={user} />
            }
          />
          {/*Admin page */}
          <Route exact path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </ConfigProvider>
  </HelmetProvider>
);

export default App;
