/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import "../ads/css/index.scss";

import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import { serverUrl } from "../../../constants";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingBgBlack from "../loading/bgBlack";

function Ads() {
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(serverUrl + "/api/homePage/adsBanners");
        const data = await response.json();
        setAds(data.formData);
      } catch (error) {
        console.error("Error fetching the news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <LoadingBgBlack loading={loading} />;
  }

  if (ads.length <= 0) {
    return <div></div>;
  }

  const settings = {
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,

    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          variableWidth: false,
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="ads-container">
      <div className="container">
        <div className="slider-container">
          <Slider {...settings}>
            {ads.map((item, index) => (
              <div key={index} className="image-container">
                <div className="overlay-container">
                  <img src={item.image} className="responsive-image"></img>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Ads;
