/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import "../footer/css/index.scss";
import { Icon } from "@iconify/react";

function Footer() {
  return (
    <footer>
      <div
        style={{
          paddingTop: 48,
          paddingBottom: 48,
          background: "#181818",
        }}
        className="front-footer"
      >
        <div className="container" style={{ paddingInline: 24 }}>
          <div className="footer-content">
            <div className="title">KK WEEKLY NEWS</div>
            <div className="address">
              <Icon
                icon="mdi:address-marker"
                style={{
                  color: "white",
                  marginRight: "16px",
                  width: "24px",
                  height: "30px",
                  verticalAlign: "middle",
                }}
              />
              303 หมู่ 12 ต.บ้านเป็ด อ.เมือง จ.ขอนแก่น
            </div>

            <div
              className="phone"
              onClick={() => {
                window.open("tel:+66938892644");
              }}
            >
              <div className="phone-icon">
                <img src="/assets/images/logo/phone-logo.png"></img>
              </div>
              <div className="phone-info">
                <div className="phone-info-container">
                  <label className="line1">โทร</label>
                  <br />
                  <label className="line2">080-559-9052</label>
                </div>
              </div>
            </div>

            <div className="social-line">
              <a
                href="https://www.facebook.com/profile.php?id=61558506964145&mibextid=ZbWKwL"
                target="_blank"
              >
                <div className="facebook-icon">
                  <Icon
                    icon="ic:baseline-facebook"
                    style={{
                      color: "white",
                      width: "80%",
                      height: "80%",
                    }}
                  />
                </div>
              </a>
              <a href="https://line.me/ti/p/QQL5Ck4hjo" target="_blank">
                <div className="line-icon">
                  <Icon
                    icon="mingcute:line-app-fill"
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                </div>
              </a>
              <a href="tel:080-559-9082" target="_blank">
                <div className="call-phone-icon">
                  <Icon
                    icon="mingcute:whatsapp-line"
                    style={{ color: "white", width: "80%", height: "80%" }}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav
        style={{
          backgroundColor: "#99251C",
          color: "white",
          paddingTop: 15,
          paddingBottom: 15,
          fontWeight: 600,
          textAlign: "center",
        }}
      >
        <div className="container">
          <label>Copyright © 2024 KK WEEKLY NEWS All Right Reserved.</label>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
