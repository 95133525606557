/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useRef } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Input,
  Popconfirm,
  Select,
  Upload,
  // DatePicker
} from "antd";
import { FaCog } from "react-icons/fa";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { apiServerUrl, server } from "../../../../constants";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  deleteNewsByIdFetch,
  getNewsFetch,
  insertNewsFetch,
  updateNewsFetch,
} from "./API";

const { Option } = Select;
// const { RangePicker } = DatePicker

const columns = [
  {
    title: "No.",
    dataIndex: "index",
    width: "10%",
  },
  {
    title: "รายการข่าว",
    dataIndex: "title",
    width: "30%",
  },
  {
    title: "รูปภาพหน้าปก",
    dataIndex: "imageThumbnail",
    width: "20%",
    render: (text, record) => {
      return (
        <>
          <img
            src={record.imageThumbnail}
            alt={record.title}
            style={{ width: 100 }}
          />
        </>
      );
    },
  },
  {
    title: "สถานะ",
    width: "10%",
    render: (text, record) => {
      return (
        <>
          {record.isActive ? (
            <label>แสดงข้อมูล</label>
          ) : (
            <label>ปิดแสดงข้อมูล</label>
          )}
        </>
      );
    },
  },
  {
    title: "วันที่สร้าง",
    dataIndex: "createdAt",
    width: "10%",
  },
  {
    title: "เเก้ไขล่าสุด",
    dataIndex: "updatedAt",
    width: "10%",
  },
  {
    title: <FaCog />,
    dataIndex: "operator",
    align: "center",
    width: "10%",
  },
];

const formatDate = "DD/MM/YYYY";

export default function VehicleManage(props) {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [formNews] = Form.useForm();
  // const [formPropertyGallery] = Form.useForm()
  const [formSearch] = Form.useForm();

  const accessToken = sessionStorage.getItem("accessToken");

  const [newsId, setNewsId] = useState();

  const [detail, setDetail] = useState();
  const detailRef = useRef();

  const [imageThumbnailURL, setImageThumbnailURL] = useState({
    loading: false,
    imageUrl: null,
  });

  const pageCurrentRef = useRef(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const searchNameRef = useRef("");

  const [modalNews, setModalNews] = useState({
    isShow: false,
    title: null,
  });

  const optionNewsImage = {
    name: "file",
    action: `${apiServerUrl}${server.UPLOAD_FILE}?type=news`,
    data: {
      name: "admin",
    },
    headers: {
      "x-access-token": accessToken,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        let result = info.file.response;
        if (result?.isSuccess) {
          console.log("A1 --- : ", result);
          setImageThumbnailURL({
            imageUrl: result.formData.fileUrl,
            loading: false,
          });
        }
      } else {
        setImageThumbnailURL({
          imageUrl: imageThumbnailURL.imageUrl,
          loading: true,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const getNewsAll = async (title) => {
    setLoading(true);

    let param = {
      title,
      isActive: "",
      page: pageCurrentRef.current,
      size: pageSize,
    };
    const result = await getNewsFetch(param, null, accessToken);
    // console.log("getNewsFetch : ", result)
    setTotal(result?.totalItems);
    let tempList = [];
    result?.results?.map((val, index) => {
      tempList.push({
        index: pageCurrentRef.current * pageSize - 10 + (index + 1),
        imageThumbnail: val.imageThumbnail, // รูปภาพหน้าปก
        title: val.title, //
        keyword: val.keyword, //
        countViews: val.countViews, //
        isActive: val.isActive, // สถานะการใช้งาน
        createdAt: val.createdAt
          ? moment(val.createdAt).format(formatDate)
          : "-",
        updatedAt: val.updatedAt
          ? moment(val.updatedAt).format(formatDate)
          : "-",
        operator: (
          <>
            <Button
              style={{
                width: 35,
                backgroundColor: "orange",
                border: "1px solid orange",
                color: "white",
                borderRadius: 50,
              }}
              onClick={async () => {
                formNews.setFieldsValue({
                  id: val.id, //
                  newsId: val.id, //
                  title: val.title, //
                  keyword: val.keyword, //
                  countViews: val.countViews, //
                  isActive: val.isActive, //
                });

                setImageThumbnailURL({
                  loading: false,
                  imageUrl: val.imageThumbnail,
                });

                setNewsId(val.id);
                // console.log("newsId : ", val.id)

                setDetail(val.detail ?? "");
                detailRef.current = val.detail ?? "";

                setModalNews({ isShow: true, title: "edit" });
              }}
            >
              <div style={{ marginTop: 0, marginLeft: -8 }}>
                <Icon
                  icon="typcn:edit"
                  style={{ color: "white", width: 20, height: 20 }}
                />
              </div>
            </Button>
            {"  "}

            <Popconfirm
              title="คุณยืนยันลบหรือไม่ ?"
              okText={<span style={{ width: 50 }}>ใช่</span>}
              onConfirm={async () => {
                await handleNewsDelete(val.id);

                // reload
                await getNewsAll("");
              }}
              cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
            >
              <Button
                danger
                type="primary"
                style={{
                  width: 35,
                  borderRadius: 50,
                }}
              >
                <div style={{ marginTop: 0, marginLeft: -8 }}>
                  <Icon
                    icon="fluent:delete-16-regular"
                    style={{ color: "white", width: 20, height: 20 }}
                  />
                </div>
              </Button>
            </Popconfirm>
          </>
        ),
      });
    });
    setList(tempList);
    searchNameRef.current = title;

    setLoading(false);
  };

  const onNewsFinish = async (values) => {
    let param = {
      id: values.id ? values.id : "",
    };

    let body = {
      slugName: "slugName",
      imageThumbnail: imageThumbnailURL.imageUrl
        ? imageThumbnailURL.imageUrl
        : "",
      title: values.title ? values.title : "",
      detail: detailRef.current ? detailRef.current : "",
      keyword: values.keyword ? values.keyword : "",
      countViews: values.countViews ? values.countViews : 0,
      isActive: values.isActive ? 1 : 0,
    };
    // console.log("body : ", body)

    if (modalNews.title === "add") {
      const result = await insertNewsFetch(null, body, accessToken);
      if (result.isSuccess) {
        Notification("success", "สร้างสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถสร้างได้ กรุณาลองใหม่อีกครั้ง");
      }
    } else if (modalNews.title === "edit") {
      const result = await updateNewsFetch(param, body, accessToken);
      if (result.isSuccess) {
        Notification("success", "เเก้ไขสำเร็จ");
      } else {
        Notification("error", "ไม่สามารถเเก้ไขได้ กรุณาลองใหม่อีกครั้ง");
      }
    }

    // reload
    getNewsAll("");

    // set default
    setFormNewsDefault();
  };

  // const onPropertyGalleryFinish = async (values) => {

  // }

  const onSearchFinish = async (values) => {
    let title = values?.title ? values.title : "";
    await getNewsAll(title);
  };

  const handleNewsDelete = async (id) => {
    let param = {
      id,
    };
    const result = await deleteNewsByIdFetch(param, null, accessToken);
    if (result.isSuccess) {
      Notification("success", "ลบสำเร็จ");
    } else {
      Notification("error", "ไม่สามารถลบได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const onPagine = (n) => {
    pageCurrentRef.current = n.current;
    getNewsAll(searchNameRef.current);
  };

  const setFormNewsDefault = () => {
    formNews.setFieldsValue({
      id: undefined, //
      newsId: undefined, //
      title: undefined, //
      keyword: undefined, //
      countViews: undefined, //
      isActive: undefined, //
    });

    setImageThumbnailURL({
      loading: false,
      imageUrl: null,
    });

    setDetail("");
    detailRef.current = "";

    setModalNews({
      isShow: false,
      title: null,
    });
  };

  const getBaseApi = async () => {
    getNewsAll("");
  };

  useEffect(() => {
    // console.log("accessToken : ", accessToken)
    getBaseApi();
  }, []);

  return (
    <Row>
      <Col span={12}>
        <label>จัดการข่าว</label>
      </Col>

      <Col span={12} style={{ paddingBottom: 20 }}>
        <Form form={formSearch} layout="vertical" onFinish={onSearchFinish}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ paddingLeft: 10 }}>
              <Form.Item
                // label="ชื่อรายการข่าว"
                name="title"
                style={{ width: "100%" }}
              >
                <Input placeholder="ค้นหารายการข่าว" />
              </Form.Item>
            </div>

            {/* <div style={{ paddingLeft: 10 }}>
                            <Form.Item
                                label="ช่วงวันที่สร้าง" name="dateRange"
                                style={{ width: '100%' }}
                            >
                                <RangePicker />
                            </Form.Item>
                        </div> */}

            <div style={{ paddingLeft: 10, marginTop: -24 }}>
              <Button
                style={{ float: "right", width: 70 }}
                type="primary"
                onClick={() => formSearch.submit()}
              >
                ค้นหา
              </Button>
            </div>
          </div>
        </Form>
      </Col>

      <Col span={24} style={{ paddingBottom: 20 }}>
        <Button
          type="primary"
          style={{ float: "right" }}
          onClick={() => {
            formNews.resetFields();

            setModalNews({
              isShow: true,
              title: "add",
            });
          }}
        >
          เพิ่มรายการ
        </Button>
      </Col>

      <Col span={24}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            current: pageCurrentRef.current,
            pageSize: pageSize,
            total: total,
          }}
          onChange={(n) => onPagine(n)}
        ></Table>
      </Col>

      <Modal
        title={
          <strong>
            <label className="topic-color-bold">
              {modalNews.title === "add" ? "เพิ่มข้อมูล" : "เเก้ไขข้อมูล"}
            </label>
          </strong>
        }
        visible={modalNews.isShow}
        zIndex={999}
        onCancel={() => {
          // default
          setFormNewsDefault();
        }}
        width={"90%"}
        onOk={() => {
          formNews.submit();
        }}
        okText={<label style={{ width: 50, cursor: "pointer" }}>บันทึก</label>}
        cancelText={
          <label style={{ width: 50, cursor: "pointer" }}>ยกเลิก</label>
        }
      >
        <Form layout="vertical" form={formNews} onFinish={onNewsFinish}>
          <Row gutter={[24, 0]}>
            <Col xs={24} md={12} xl={12}>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item name="id" style={{ display: "none" }}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="newsId" style={{ display: "none" }}>
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="title"
                    label="ชื่อข่าว"
                    rules={[{ required: true, message: "กรุณากรอกชื่อข่าว" }]}
                  >
                    <Input placeholder="ชื่อข่าว" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="keyword"
                    label="Keywords"
                    rules={[{ required: true, message: "กรุณากรอก Keywords" }]}
                  >
                    <Input placeholder="Keywords" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="detail"
                    label="รายละเอียดย่อย"
                    rules={[
                      { required: true, message: "กรุณากรอกรายละเอียดย่อย" },
                    ]}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "|",
                            "fontfamily",
                            "fontsize",
                            "|",
                            "bold",
                            "italic",
                            "underline",
                            "|",
                            "alignment",
                            "|",
                            "fontColor",
                            "fontBackgroundColor",
                            "|",
                            "bulletedList",
                            "numberedList",
                            "todoList",
                            "|",
                            "code",
                            "codeBlock",
                            "|",
                            "undo",
                            "redo",
                            "|",
                            "insertTable",
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                          ],
                          removeButtons: "Subscript,Superscript",
                        },
                        table: {
                          contentToolbar: [
                            "tableColumn",
                            "tableRow",
                            "mergeTableCells",
                          ],
                        },
                        height: 350,
                      }}
                      data={detail}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        detailRef.current = data;
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="isActive"
                    label="เเสดงข่าวหรือไม่ ?"
                    rules={[
                      { required: true, message: "กรุณาเลือกสถานะการเเสดง" },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      allowClear
                    >
                      <Option key={0} value={true}>
                        เปิด
                      </Option>
                      <Option key={1} value={false}>
                        ปิด
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={12} xl={12}>
              <Row gutter={[24, 0]}>
                <Col xs={24} md={12} xl={12}>
                  <div style={{ display: "grid" }}>
                    <label style={{ paddingBottom: 6 }}>ภาพปกข่าว</label>
                    {imageThumbnailURL?.imageUrl ? (
                      <img
                        style={{
                          borderRadius: 8,
                          maxWidth: "100%",
                          border: "1px solid #EEEEEE",
                        }}
                        src={`${imageThumbnailURL.imageUrl}`}
                      />
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          borderRadius: 8,
                          border: "1px solid #C4C4C4",
                        }}
                        src={`./assets/images/default/df-img.png`}
                      />
                    )}
                    <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                      <Upload
                        {...optionNewsImage}
                        accept="image/jpeg, image/png, image/jfif"
                        style={{ width: "100%" }}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <Button
                          type="default"
                          style={{ width: "100%" }}
                          icon={
                            imageThumbnailURL.loading ? (
                              <LoadingOutlined />
                            ) : (
                              <UploadOutlined />
                            )
                          }
                        >
                          อัพโหลดรูปภาพ
                        </Button>
                      </Upload>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
}
