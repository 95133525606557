import { server } from "../../../../../constants"
import { httpClient } from "../../../../../utils/HttpClient"

// News
const insertNewsFetch = async (param, body, accessToken) => { // Done
    try {
        console.log("insertNewsFetch: ", server.INSERT_NEWS_URL, body, accessToken);
        const result = await httpClient.post(server.INSERT_NEWS_URL, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const getNewsFetch = async (param, body, accessToken) => { // Done
    try {
        const result = await httpClient.get(server.GET_NEWS_URL + `?title=${param.title}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const getNewsByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.get(server.GET_NEWS_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        if (result.data.isSuccess) {
            return result.data.formData
        } else {
            return null
        }
    } catch (err) { // status 404
        return null
    }
}

const updateNewsFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.put(server.UPDATE_NEWS_URL + `/${param.id}`, body, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

const deleteNewsByIdFetch = async (param, body, accessToken) => {
    try {
        const result = await httpClient.delete(server.DELETE_NEWS_BY_ID_URL + `/${param.id}`, {
            headers: {
                'x-access-token': accessToken
            }
        })
        return result?.data ?? null
    } catch (err) { // status 404
        return null
    }
}

export {
    // get
    getNewsFetch,
    getNewsByIdFetch,

    // insert
    insertNewsFetch,

    // update
    updateNewsFetch,

    // delete
    deleteNewsByIdFetch
}
