import React from "react";
import Header from "../../common/components/header";

const NotFound = () => {
  return (
    <>
      <Header />
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <img src="/assets/images/not_found/error.jpg" width={550} />
      </div>
    </>
  );
};

export default NotFound;
