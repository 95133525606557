/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useState, useEffect } from "react";
import "./css/index.scss";
import "./css/paginate.scss";
import { Input, Row, Col, Button, Flex, Pagination } from "antd";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import "moment/locale/th";
import LoadingBgBlack from "../loading/bgBlack";

import { useNavigate } from "react-router-dom";
import { serverUrl } from "../../../constants";

function NewsList() {
  const navigate = useNavigate();
  const { Search } = Input;

  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(27);
  const [searchTitle, setSearchTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalNews, setTotalNews] = useState(0);

  useEffect(() => {
    fetchPageData(currentPage);
  }, [currentPage, searchTitle]);

  const fetchPageData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(serverUrl + "/api/homePage/news", {
        params: {
          page: currentPage,
          size: newsPerPage,
          title: searchTitle,
        },
      });
      setNews(response.data.formData.results);
      setTotalNews(response.data.formData.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const onChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearchTitle(e.target.value);
  };

  return (
    <div className="news-list-container">
      <div className="container">
        {/* <div style={{ paddingBottom: 8 }}> */}
        <div className="search">
          <div className="search-input">
            <Search enterButton onChange={onSearch} value={searchTitle} />
          </div>
        </div>
        {/* </div> */}

        <div className="news-container">
          <div className="title">
            <Icon
              icon="entypo:new"
              style={{
                color: "white",
                marginRight: "8px",
                width: "20px",
                height: "20px",
              }}
            />
            <span>ข่าวล่าสุด</span>
          </div>
          {!loading ? (
            <Row gutter={[8, 8]}>
              {news.map((item, index) => (
                <Col
                  span={24}
                  md={index === 0 ? 24 : 12}
                  xl={index === 0 ? 12 : 6}
                  key={index}
                >
                  {index === 0 ? (
                    <div className="big-news-card">
                      <div className="image-container">
                        <img src={item.imageThumbnail}></img>
                        <div className="overlay">
                          <div className="detail">
                            <p>{item.title}</p>
                            <Flex justify="space-between" align="center">
                              <span className="date">
                                {moment(item.createdAt).fromNow()}
                              </span>
                              <Button
                                type="primary"
                                onClick={() => {
                                  navigate("/news/" + item.id);
                                }}
                              >
                                อ่านต่อ
                              </Button>
                            </Flex>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="small-news-card">
                      <div className="image-container">
                        <img src={item.imageThumbnail}></img>
                      </div>
                      <div className="news-content">
                        <div className="news-title">{item.title}</div>
                        <Flex justify="space-between" align="center">
                          <span className="date">
                            {moment(item.createdAt).fromNow()}
                          </span>
                          <Button
                            type="primary"
                            onClick={() => {
                              navigate("/news/" + item.id);
                            }}
                          >
                            อ่านต่อ
                          </Button>
                        </Flex>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          ) : (
            <LoadingBgBlack loading={loading} />
          )}
          {news.length > 0 ? (
            <Row className="paginate-container">
              <Pagination
                size="small"
                current={currentPage}
                total={totalNews}
                defaultPageSize={newsPerPage}
                onChange={onChange}
              />
            </Row>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default NewsList;
