/* eslint-disable react-hooks/exhaustive-deps */
import { Element } from "react-scroll";
import Header from "../../common/components/header";
import Footer from "../../common/components/footer";
import HotNews from "../../common/components/hotNews";
import Ads from "../../common/components/ads";
import NewsList from "../../common/components/newsList";

export default function Home() {
  return (
    <>
      <Header />

      <div style={{ paddingTop: 70, paddingBottom: 0, background: "black" }}>
        <Element name="hotNews">
          <HotNews />
        </Element>
        <Element name="ads">
          <Ads />
        </Element>
        <Element name="news">
          <NewsList />
        </Element>
      </div>

      <Footer />
    </>
  );
}
