/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import "../hotNews/css/index.scss";
import { Flex, Button } from "antd";
import { Icon } from "@iconify/react";

import React, { useState, useEffect } from "react";

import { serverUrl } from "../../../constants";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import "moment/locale/th";

import { useNavigate } from "react-router-dom";
import LoadingBgBlack from "../loading/bgBlack";

function HotNews() {
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          serverUrl + "/api/homePage/topViewNewsToday"
        );
        const data = await response.json();
        setNews(data.formData);
      } catch (error) {
        console.error("Error fetching the news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (loading) {
    return <LoadingBgBlack loading={loading} />;
  }

  if (news.length <= 0) {
    return <div></div>;
  }

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="hotnews-container">
      <div className="container">
        <div className="slider-container">
          <div className="title">
            <Icon
              icon="bi:fire"
              style={{ color: "white", marginRight: "8px" }}
            />
            <span>เข้าชมมากที่สุดวันนี้</span>
          </div>
          {news.length > 0 ? (
            <Slider className="slider-items" {...settings}>
              {news.map((item, index) => (
                <div key={index} className="image-container">
                  <div className="overlay-container">
                    <img
                      src={item.news.imageThumbnail}
                      className="responsive-image"
                    ></img>
                    <div className="overlay">
                      <div className="detail">
                        <p>{item.news.title}</p>
                        <Flex justify="space-between" align="center">
                          <span className="date">
                            {moment(item.news.createdAt).fromNow()}
                          </span>
                          <Button
                            type="primary"
                            onClick={() => {
                              navigate("/news/" + item.news.id);
                            }}
                          >
                            อ่านต่อ
                          </Button>
                        </Flex>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HotNews;
